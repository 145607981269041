<template>
  <div>
    <!-- 엑셀업로드 -->
    <c-card title="엑셀업로드" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="적용" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
          <c-text
            :editable="editable"
            :disabled="true"
            label="담당부서/이름/일자"
            name="request"
            v-model="request">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
      tabHeight="660px"
      gridHeight="630px"
    />
  </div>
</template>

<script>
export default {
  name: 'checkup-result-excel-upload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['userId'], // 데이터들의 키 속성값
        taskClassCd: 'HEA_CYCLE', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [],
        data: [],
        deptCd: '',
        processCd: '',
        workplace: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code 
      this.setHeader();
    // list setting
    },
    setHeader() {
        this.excelUploadInfo.columns = [
          [
            {
              required: true,
              name: 'empNo',
              field: 'empNo',
              label: '사번',
              align: 'center',
              style: 'width:100px',
              type: "readonly",
              sortable: false,
            },
            {
              required: true,
              name: "userName",
              field: "userName",
              label: "대상자",
              style: 'width:100px',
              align: "center",
              type: "readonly",
              sortable: false,
            },
            {
              name: 'typeName',
              field: 'typeName',
              label: '검진종류',
              align: 'center',
              style: 'width:100px',
              type: "readonly",
              sortable: false,
            },
            {
              name: 'cycleName',
              field: 'cycleName',
              label: '검진주기',
              align: 'center',
              style: 'width:100px',
              type: "readonly",
              sortable: false,
            },
            {
              name: 'checkupStatus',
              field: 'checkupStatus',
              label: '비고',
              align: 'left',
              style: 'width:300px',
              sortable: false,
            },
            
          ]
        ]
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }
        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              // 에러가 있는 데이터가 존재합니다.\n\r에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?
              message: '에러가 있는 데이터가 존재합니다.\n\r에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            this.$emit('closePopup', this.excelUploadInfo.data[0], this.excelUploadInfo.plantCd);
          }
        }
      }
    }
  }
};
</script>
